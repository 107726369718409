import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { IoIosPhonePortrait } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt, FaInstagram } from "react-icons/fa";
import { GoClock } from "react-icons/go";
import { GrMapLocation } from "react-icons/gr";
import { SlSocialLinkedin } from "react-icons/sl";
import { FaXTwitter } from "react-icons/fa6";
import { LiaSnapchat } from "react-icons/lia";

import './Contact.css'



function Contact() {


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();
        const servicesId = "service_xcauxxu";
        const templateId = "template_8w7wkhr";
        const publikKey = "7KCPH3nk7GgWdfiKQ";

        const templateParams = {
            from_name: name,
            from_email: email,
            subject: subject,
            phone_num: phone,
            message: message
        };
        emailjs.send(servicesId, templateId, templateParams, publikKey)
            .then((response) => {
                alert("Email sent successfully \n تم الإرسال بنجاح", response);
                setName('');
                setEmail('');
                setPhone('');
                setSubject('');
                setMessage('');
            })
            .catch((error) => {
                alert("Failed to send message \n تعذر إرسال الرسالة", error);
            });
    };

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div id="contact">
                <br />
                <br />
                <div className='contactH'>
                    <h1 data-aos="zoom-in"
                        data-aos-offset="100"
                        data-aos-duration="500"><span>تواصل معنا</span></h1>
                </div>
                <div className="contact-container" dir='rtl'>
                    <div className="conatct-top">
                        <div className="cont-left">
                            <div className="bord phone"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="300">
                                <i className='phone'><IoIosPhonePortrait /></i>
                                <div className='bord-cont'>
                                    <p className='H'>الهاتف</p>
                                    <p dir='ltr'> +966551155707</p>
                                </div>

                            </div>
                            <div className="bord mail"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="500">
                                <i className='mail'><MdEmail /></i>
                                <div className='bord-cont'>
                                    <p>البريد الإلكتروني</p>
                                    <p>info@i-a-lawco.com</p>
                                </div>

                            </div>
                            <div className="bord address"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="700">
                                <i className='address'><FaMapMarkerAlt /></i>
                                <div className='bord-cont'>
                                    <p>العنوان</p>
                                    <p>المملكة العربية السعودية - جدة - مبنى حسن سكوير - الدور الأول مكتب 110</p>
                                </div>

                            </div>
                            <div className="bord time"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="900">
                                <i className='time'><GoClock /></i>
                                <div className='bord-cont'>
                                    <p>مواعيد العمل</p>
                                    <p>من 9 صباحا حتى 9 مساء ماعدا السبت والجمعة</p>
                                </div>
                            </div>
                            <div className="bordI"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="900" dir='ltr'>
                                <a target="_blank"
                                    rel="noreferrer" href="https://www.google.com/maps?ll=21.613534,39.154892&z=16&t=m&hl=en&gl=EG&mapclient=embed&cid=14346717577956034144"><i className='Linkbt'><GrMapLocation /></i></a>
                                <a target="_blank"
                                    rel="noreferrer" href="https://www.linkedin.com/in/legal-company-378620328/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"><i className='Linkbt'><SlSocialLinkedin /></i></a>
                                <a target="_blank"
                                    rel="noreferrer" href="https://snapchat.com/t/W0NxoL9c"><i className='Linkbt'><LiaSnapchat /></i></a>
                                <a target="_blank"
                                    rel="noreferrer" href="https://x.com/ialawcom?s=11"><i className='Linkbt'><FaXTwitter /></i></a>
                                <a target="_blank"
                                    rel="noreferrer" href="https://www.instagram.com/legalcompany2024?igsh=M3I4bjYxcjQycGdy&utm_source=qr"><i className='Linkbt'><FaInstagram /></i></a>
                            </div>
                        </div>

                        <div className="cont-right">
                            <div className="map" data-aos="fade-down"
                                data-aos-offset="100"
                                data-aos-duration="1000">
                                <iframe
                                    title="contact"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3709.292226332405!2d39.15489240000001!3d21.6135336!2m3!1f0!2f0!3f0!3m2!1i1
                                    024!2i768!4f13.1!3m3!1m2!1s0x15c3d1005cbfbff9%3A0xc719c79ffeec0660!2z2LTYsdmD2Kkg2KfYqNix2KfZh9mK2YUg2KfZhNiy2YfYsdin2YbZiiDZiNin2K3Z
                                    hdivINin2YTZhdin2YTZg9mKINmE2YTZhdit2KfZhdin2Kkg2YjYp9mE2KfYs9iq2LTYp9ix2KfYqiDYp9mE2YLYp9mG2YjZhtmK2Kk!5e0!3m2!1sen!2seg!4v1726299087088!5m2!1sen!2seg"
                                    allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="bot-container" dir='rtl'>
                    <div className="cont-input">
                        <div className="contact-but"
                            data-aos="fade-down-left"
                            data-aos-offset="150"
                            data-aos-duration="500">
                            <h2 className='msgH'>تواصل معنا الآن</h2>

                            <div className="form-container">
                                <div className="input">
                                    <form onSubmit={sendEmail} className='emailForm'>
                                        <div className='top-col'>
                                            <div className='top-col-row'>
                                                <input type="email"
                                                    name='email'
                                                    placeholder="البريد الإلكتروني"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    data-aos="fade-left"
                                                    data-aos-offset="150"
                                                    data-aos-duration="100"
                                                    required
                                                />
                                                <input type="text"
                                                    name='name'
                                                    placeholder="الإسم"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    data-aos="fade-right"
                                                    data-aos-offset="150"
                                                    data-aos-duration="900"
                                                    required
                                                />
                                            </div>

                                            <div className='top-col-row'>
                                                <div>
                                                    <input type="text"
                                                        placeholder="الموضوع"
                                                        value={subject}
                                                        onChange={(e) => setSubject(e.target.value)}
                                                        data-aos="fade-left"
                                                        data-aos-offset="50"
                                                        data-aos-duration="100"
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <input
                                                        name="phone"
                                                        placeholder="الجوال"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        data-aos="fade-right"
                                                        data-aos-offset="80"
                                                        data-aos-duration="900"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-but'
                                            data-aos="fade-right"
                                            data-aos-offset="80"
                                            data-aos-duration="900">
                                            <textarea
                                                placeholder="رسالتك"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)} required />
                                            <div className='btn-container' >
                                                <button type='submit' className='sub-btn'>إرسال</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact