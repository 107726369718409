import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

// Create the context
const BlogContext = createContext();

// Create a provider component
export const BlogProvider = ({ children }) => {
    const [blogs, setBlogs] = useState([]); // Ensure blogs starts as an array
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const storedBlogs = sessionStorage.getItem('blogs');
                if (storedBlogs) {
                    setBlogs(JSON.parse(storedBlogs));
                } else {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blogs/all-blogs`);
                    const blogsArray = response.data.map((blog) => ({
                        ...blog,
                        createdAt: new Date(blog.createdAt),
                    })).sort((a, b) => b.createdAt - a.createdAt);


                    sessionStorage.setItem('blogs', JSON.stringify(blogsArray));
                    setBlogs(blogsArray);
                }
            } catch (error) {
                console.error('Error fetching blogs:', error);
                setError('Failed to load blogs. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <BlogContext.Provider value={{ blogs, loading, error }}>
            {children}
        </BlogContext.Provider>
    );
};

// Custom hook to use the BlogContext
export const useBlogs = () => {
    return useContext(BlogContext);
};
