import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import './ExistingBlogsPage.css';
import AdBlogCard from './AdBlogCard';

import axios from 'axios';
import { PulseLoader } from 'react-spinners';

const ExistingBlogsPage = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 9;
    const navigate = useNavigate();
    const toast = useRef(null);

    // Check authentication state
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        }

    }, [navigate]);


    useEffect(() => {
    const fetchBlogs = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blogs/all-blogs`);
            // Sort blogs from newest to oldest based on the createdAt field
            const sortedBlogs = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setBlogs(sortedBlogs);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    fetchBlogs();
}, []);


    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#ffffff", height: "100vh" }}>
                <PulseLoader color="#C79149" />
            </div>
        );
    }

    if (!blogs) return <p>لا يوجد مدونات</p>;
    if (error) return <p>Error: {error}</p>;

    const handleDeleteBlog = async (id) => {
        console.log("Deleting blog with ID:", id); // Log the ID
        const confirmed = window.confirm(`هل ترغب في حذف المدونة ؟`);
        if (confirmed) {

            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/api/blogs/delete/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`, // Include token for authentication
                        'Content-Type': 'multipart/form-data', // Set content type for file upload
                    },
                });

                setBlogs(prevBlogs => prevBlogs.filter(blog => blog.id !== id));
                toast.current.show({ severity: 'success', summary: 'تم', detail: `تم حذف المدونة`, life: 3000 });

            } catch (err) {
                console.log(err);
                toast.current.show({ severity: 'error', summary: 'خطأ', detail: 'خطأ في حذف المدونة', life: 3000 });
            }
        }
    };

    const filteredBlogs = blogs.filter(blog =>
        blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        blog.content.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredBlogs.length / itemsPerPage);
    const paginatedBlogs = filteredBlogs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePaginationClick = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const renderPagination = () => {
        const paginationItems = [];
        paginationItems.push(
            <Pagination.Item key={1} active={currentPage === 1} onClick={() => handlePaginationClick(1)}>
                1
            </Pagination.Item>
        );

        if (currentPage > 2) {
            paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }

        if (currentPage > 1 && currentPage <= totalPages - 1) {
            paginationItems.push(
                <Pagination.Item key={currentPage} active onClick={() => handlePaginationClick(currentPage)}>
                    {currentPage}
                </Pagination.Item>
            );
        }

        if (currentPage < totalPages - 1) {
            paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }

        if (totalPages > 1) {
            paginationItems.push(
                <Pagination.Item key={totalPages} active={currentPage === totalPages} onClick={() => handlePaginationClick(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return paginationItems;
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <div id='Eblogs'>
            <Toast ref={toast} />
            <div className='Eblogs'>
                <div className='Eblog-head'>
                    <div className='Eblog-head1'>
                        <button onClick={handleLogout} className="logout-button">تسجيل خروج</button>
                        <Link to='/' dir='rtl'>الرئيسية</Link>
                        <Link to='/admin' dir='rtl'>اضف مدونة +</Link>
                    </div>
                    <div className='Eblog-head2'>
                        <h3>مدونات الموقع</h3>
                        <input
                            type="text"
                            placeholder="بحث"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="search-bar"
                        />
                    </div>
                </div>
                <div className='admin-blog-container'>
                    <div className='admin-card-container'>
                        {paginatedBlogs.map((blog) => (
                            <div className='admin-Bcard' key={blog.id}>
                                <AdBlogCard
                                    key={blog.id}
                                    id={blog.id}
                                    Pimg={blog.image}
                                    title={blog.title}
                                    content={blog.content}
                                    date={blog.createdAt ? new Date(blog.createdAt).toLocaleDateString() : 'Unknown date'}

                                    onClick={() => handleDeleteBlog(blog.id)} // Call handleDeleteBlog
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {totalPages > 1 && (
                <div className='PagiNation'>
                    <Pagination>
                        <Pagination.First onClick={() => handlePaginationClick(currentPage - 1)} disabled={currentPage === 1} />
                        {renderPagination()}
                        <Pagination.Last onClick={() => handlePaginationClick(currentPage + 1)} disabled={currentPage === totalPages} />
                    </Pagination>
                </div>
            )}
        </div>
    );
};

export default ExistingBlogsPage;
