import React from 'react'
import { Link } from 'react-router-dom'


const AdBlogCard = (props) => {


    const truncatedContent = props.content.length > 500
        ? props.content.substring(0, 500) + "..."
        : props.content;

    return (
        <div id='blogcard'>
            <div className='blogcard' style={{ position: "relative" }} dir='rtl' id={props.id}>
                <Link to={`/blog/${props.id}/${props.title}`}>
                    <div className='blogcard__image'>
                        <img src={props.Pimg} alt='img' />
                    </div>
                    <div className='BlogCard__content'>
                        <h6 className='strong'><strong>{props.title}</strong></h6>
                        <h6><small>{props.date}</small></h6>
                        <p dangerouslySetInnerHTML={{ __html: truncatedContent }} />
                    </div>
                </Link>
                <div className='Adhead'>
                    <button onClick={props.onClick}>حذف</button>
                </div>
            </div>
        </div>
    )
}

export default AdBlogCard